import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

interface Props {
  className?: string;
  image?: string;
  title?: string;
  subtitle?: string;
  description?: string;
}

const Card = ({
  className,
  title,
  image,
  description,
  subtitle,
  children
}: PropsWithChildren<Props>) => (
  <CardContainer className={className} data-aos="fade-up">
    {image && (
      <CardImageContainer>
        <img src={image} alt={`${title} image`} />
      </CardImageContainer>
    )}
    <CardContentContainer>
      {title && <CardTitle>{title}</CardTitle>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {description && (
        <CardDescriptionContainer>
          <RichText source={description} />
        </CardDescriptionContainer>
      )}
      {children && (
        <CardDescriptionContainer>{children}</CardDescriptionContainer>
      )}
    </CardContentContainer>
  </CardContainer>
);

const Subtitle = styled.p`
  font-weight: 700;
  padding-bottom: 15px;
  width: 100%;
`;

const CardContainer = styled.li`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
`;

const CardImageContainer = styled.div`
  height: 300px;
  width: 100%;
  background-color: ${({ theme }) => theme.mainColor};
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &::after {
    content: " ";
    background-color: ${({ theme }) => theme.mainColor};
    opacity: 0.35;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::before {
    content: " ";
    height: 80%;
    width: 2px;
    bottom: 0;
    background-color: #fff;
    display: block;
    right: 80px;
    position: absolute;
    z-index: 1;
  }
`;

const CardContentContainer = styled.div`
  padding: 40px 30px;
`;

const CardTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding-bottom: 15px;
  letter-spacing: 0.04em;
  line-height: 1.6em;
  color: ${({ theme }) => theme.mainColor};
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const CardDescriptionContainer = styled.div`
  text-align: justify;
`;

const RichText = styled(Markdown)`
  font-size: 14px;
  line-height: 1.8em;
  color: ${props => props.theme.colors.gray};
  p {
    margin: 10px 0;
  }
  strong {
    font-weight: 600;
    color: ${props => props.theme.textColorDark};
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    margin: 10px 0;
  }
  a {
    font-weight: 600;
    color: inherit;
    position: relative;
    text-decoration: none;
    transition: 0.3s all;
    color: ${props => props.theme.textColorDark};
    &::after {
      transition: 0.3s all;
      content: "";
      height: 1px;
      width: calc(100% + 6px);
      display: block;
      position: absolute;
      bottom: -2px;
      left: -3px;
      background-color: ${props => props.theme.textColorDark};
    }
    &:hover {
      color: ${({ theme }) => theme.mainColor};
      &::after {
        background-color: ${({ theme }) => theme.mainColor};
      }
    }
  }
`;

export default Card;
