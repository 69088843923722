import Card from "components/Card";
import Page from "components/Page";
import {
  Hero,
  HeroImageContainer,
  HeroImg,
  HeroMessageContainer,
  HeroSubtitle,
  HeroTitle,
  Section,
  SectionDescription,
  SectionInfo,
  Separator
} from "components/UI";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import React from "react";
import { PageLocale } from "types";
import styled from "styled-components";

interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          hero: {
            title: string;
            subtitle: string;
            image: string;
          };
          aboutUs: {
            title: string;
            description: string;
          };
          professionists: {
            title: string;
          };
          professionistsList: {
            title: string;
            image: string;
            description: string;
            role: string;
          }[];
          collaborators: {
            title: string;
            description: string;
          };
        }[];
      };
    };
  };
  pageContext: {
    name: string;
    locale: string;
  };
}

const AboutPage = ({ data, pageContext }: Props) => {
  const currentPage = data.page.fields.locales.find(
    ({ language }) => language === pageContext.locale
  )!;

  return (
    <Page
      title={currentPage.title}
      description={currentPage.seo.description}
      localeCode={pageContext.locale}
      image={currentPage.seo.image}
      pageLocales={data.page.fields.locales.map(
        (locale): PageLocale => ({
          code: locale.language,
          url: locale.path
        })
      )}
    >
      <Hero secondary>
        <HeroImageContainer>
          <HeroImg src={currentPage.hero.image} alt="Studio DSG About" />
        </HeroImageContainer>
        <Wrapper>
          <HeroMessageContainer>
            <HeroTitle
              data-aos="fade-right"
              dangerouslySetInnerHTML={{ __html: currentPage.hero.title }}
            />
            <Separator light />
            <HeroSubtitle data-aos="fade-right" data-aos-delay="300">
              {currentPage.hero.subtitle}
            </HeroSubtitle>
          </HeroMessageContainer>
        </Wrapper>
      </Hero>
      <Section>
        <Wrapper data-aos="fade-up">
          <Center>
            <SectionInfo>{currentPage.aboutUs.title}</SectionInfo>
            <Separator />
            <SectionDescription>
              {currentPage.aboutUs.description}
            </SectionDescription>
          </Center>
          <Center>
            <SectionInfo>{currentPage.professionists.title}</SectionInfo>
            <Separator />
          </Center>
          <ProfessionistList>
            {currentPage.professionistsList.map(item => (
              <AboutCard
                key={item.title}
                title={item.title}
                subtitle={item.role}
                image={item.image}
                description={item.description}
              />
            ))}
          </ProfessionistList>
          <Center>
            <SectionInfo>{currentPage.collaborators.title}</SectionInfo>
            <Separator />
            <SectionDescription>
              {currentPage.collaborators.description}
            </SectionDescription>
          </Center>
        </Wrapper>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query AboutPageQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            description
            image
          }
          hero {
            title
            subtitle
            image
          }
          aboutUs {
            title
            description
          }
          professionists {
            title
          }
          professionistsList {
            title
            image
            description
            role
          }
          collaborators {
            title
            description
          }
        }
      }
    }
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  ${SectionDescription} {
    padding-bottom: 60px;
  }
`;

const ProfessionistList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
`;

const AboutCard = styled(Card)`
  margin: 20px;
  width: calc(33.33% - 40px);
  @media (max-width: 1000px) {
    width: calc(50% - 40px);
  }
  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export default AboutPage;
